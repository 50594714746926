.navigationBar {
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 30px 0;
    font-family: 'Ubuntu', sans-serif;
    transition: all 0.3s ease;
}

.navigationBar.sticky {
    padding: 15px 0;
}

.navigationBar .maxWidth {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigationBar .logo img {
    width: 48px;
    height: 48px;
}

.navigationBar .logo a {
    color: #ffffff;
    font-size: 35px;
    font-weight: 600;
}

.navigationBar .logo a span {
    color: #1e90ff;
    transition: all 0.3s ease;
}

.navigationBar .menu li {
    list-style: none;
    display: inline-block;
}

.navigationBar .menu li a {
    display: block;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin-left: 25px;
    transition: color 0.3s ease;
}

.navigationBar .menu li a:hover {
    color: #1e90ff;
}

.maxWidth {
    max-width: 1300px;
    padding: 0 80px;
    margin: auto;
}

.menuButton {
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    display: none;
}

@media (max-width: 991px) {
    .maxWidth {
        padding: 0 50px;
    }
}

@media (max-width: 947px) {
    .menuButton {
        display: block;
        z-index: 999;
    }

    .menuButton i.active:before {
        content: "\f00d";
    }

    .navigationBar .menu {
        position: fixed;
        height: 100vh;
        width: 100%;
        left: -100%;
        top: 0;
        background: #111;
        text-align: center;
        padding-top: 80px;
        transition: all 0.3s ease;
    }

    .navigationBar .menu.active {
        left: 0;
    }

    .navigationBar .menu li {
        display: block;
    }

    .navigationBar .menu li a {
        display: inline-block;
        margin: 20px 0;
        font-size: 25px;
    }

    .maxWidth {
        max-width: 930px;
    }
}

@media (max-width: 690px) {
    .maxWidth {
        padding: 0 23px;
    }
}
