.button {
    position: fixed;
    height: 45px;
    width: 42px;
    background: #1e90ff;
    right: 30px;
    bottom: 10px;
    text-align: center;
    line-height: 45px;
    color: #fff;
    z-index: 9999;
    font-size: 30px;
    border-radius: 6px;
    border-bottom-width: 2px;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
}

.button.show {
    bottom: 30px;
    opacity: 1;
    pointer-events: auto;
}

.button:hover {
    filter: brightness(90%);
}

@media (max-width: 500px) {
    .button {
        right: 15px;
        bottom: 15px;
        height: 38px;
        width: 35px;
        font-size: 23px;
        line-height: 38px;
    }
}
